import {createGlobalStyle} from 'styled-components';
import listStyles from '../styles/ui/lists';
import { theme } from './default-theme';


const globalStyles = createGlobalStyle`
  body {
    color: ${({theme}) => theme.palette.darkGrey};

    &.overflow-scroll-bar {
      overflow-y: scroll !important;
      padding-right: 0 !important;
    }

    &.overflow-hidden {
      overflow: hidden;
    }

    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }
  ${({theme}) => listStyles(theme)}
  p {
    font-size: inherit;
  }
  button {
    font-family: ${({theme}) => theme.fontFamily};
  }
  h1 {
    ${({theme}) => theme.typography.h1}
  }
  h2 {
    ${({theme}) => theme.typography.h2}
  }
  h3 {
    ${({theme}) => theme.typography.h3}
  }
  h4 {
    ${({theme}) => theme.typography.h4}
  }
  h5 {
    ${({theme}) => theme.typography.h5}
  }
  h6 {
    ${({theme}) => theme.typography.h6}
  }

  a {
    color: ${({theme}) => theme.palette.vividBlue};
  }

  iframe#launcher {
    ${({theme}) => theme.props.mobileNavDown} {
      bottom: 80px !important;
      z-index: 2 !important;
    }
  }
  
`

export default globalStyles