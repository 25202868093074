import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {createTheme, StylesProvider} from '@material-ui/core/styles';
import GlobalStyles from './global-styles';
import {inputOverrides} from './overrides';

const fontFamily = '"Open Sans", "Helvetica", "Arial", sans-serif';
// const fontSize = 14
// const htmlFontSize = 16
// const coef = fontSize / 14;

const theme = createTheme({
  fontFamily: fontFamily,
  props: {
    MuiTypography: {
      variantMapping: {
        body1: 'div', // regular
        body2: 'div', // fine print
        subTitle: 'h6'
      }
    },
    desktopNavUpPx: 690,
    mobileNavDownPx: 689,
    desktopNavUp: '@media (min-width: 690px)',
    mobileNavDown: '@media (max-width: 689px)',
    desktopMediumUpPx: 1200,
    mobileMediumDownPx: 1199,
    // typography: {
    //   pxToRem: size => `${(size / htmlFontSize) * coef}rem`,
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 840,
      lg: 1280,
      xl: 1920,
    }
  }
});

theme.palette = {
  ...theme.palette,
  black: '#000',
  white: '#fff',
  vividBlue: '#0170d4',
  vividBlueHover: '#015BAC',
  charcoal: '#2e3238',
  skyBlue: '#3fb8ff',
  skyBlueHover: '#0D7ABE',
  yellow: '#f8dd0a',
  gunmentalGrey: '#758094',
  shadeBlack: '#1E1E1E',
  darkGrey: '#7d7d7d',
  darkGrey2: '#282828',
  darkGrey3: '#414141',
  middleGrey: '#d6d6d6',
  lightGrey: '#f3f4f7',
  greyOpacity: 'rgba(46, 50, 56, 0.2)',
  greyLightOpacity: 'rgba(46, 50, 56, 0.8)',
  skyGradient: 'linear-gradient(270deg, #3FB8FF 0%, #0170D4 100%)',
  skyGradientLight: 'linear-gradient(270deg, rgb(63, 184, 255, 0.10) 0%, rgb(1, 112, 212, 0.10) 100%)',
  lightBlue: '#EBF7FF',
  background: {
    default: '#fff'
  },
  error: {
    ...theme.palette.error,
    form: '#BD10E0'
  }
};

theme.typography = {
    ...theme.typography,
    fontFamily: fontFamily,
    color: theme.palette.gunmentalGrey,
    h1: {
      ...theme.typography.h1,
      fontSize: '32px',
      fontFamily: fontFamily,
      //letterSpacing: '5px',
      fontWeight: 800,
      [theme.breakpoints.up('md')]: {
        fontSize: '42px',
        lineHeight: '1.06',
        //letterSpacing: '8px'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '60px',
        lineHeight: '1.06',
        //letterSpacing: '10px'
      }
    },
    h2: {
      ...theme.typography.h2,
      fontSize: '18px',
      fontFamily: fontFamily,
      fontWeight: 800,
      //letterSpacing: '3px',
      lineHeight: '1.2',
      [theme.breakpoints.up('md')]: {
        fontSize: '23px',
        //letterSpacing: '6px'
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '44px',
        //letterSpacing: '8px'
      }
    },
    h3: {
      ...theme.typography.h3,
      fontSize: '18px',
      fontFamily: fontFamily,
      fontWeight: 700,
      [theme.breakpoints.up('md')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '34px'
      }
    },
    h4: {
      ...theme.typography.h4,
      fontSize: '18px',
      fontFamily: fontFamily,
      fontWeight: 700,
      [theme.breakpoints.up('md')]: {
        fontSize: '21px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '28px'
      }
    },
    h5: {
      ...theme.typography.h5,
      fontSize: '16px',
      fontFamily: fontFamily,
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        fontSize: '24px'
      }
    },
    h6: {
      ...theme.typography.h5,
      fontSize: '16px',
      fontFamily: fontFamily,
      fontWeight: 700,
      [theme.breakpoints.up('lg')]: {
        fontSize: '20px'
      }
    },
    body1: {
      ...theme.typography.body1,
      color: theme.palette.darkGrey,
      fontSize: '13px',
      fontFamily: fontFamily,
      [theme.breakpoints.up('lg')]: {
        fontSize: '16px'
      }
    },
    body2: {
      ...theme.typography.body2,
      color: theme.palette.darkGrey,
      fontSize: '10px',
      fontFamily: fontFamily,
      fontWeight: 400,
      [theme.breakpoints.up('lg')]: {
        fontSize: '12px'
      }
    },
    ul: {
      fontSize: '30px'
    }
};

theme.containers = {
  main: {
    width: '1352px',
    gutter: '16px',
  },
  full: {
    width: '1600px',
  }
};

theme.overrides = {
  ...theme.overrides,
  ...inputOverrides(theme),
  MuiGrid: {
    root: {
      '&$container': {
        maxWidth: '1400px',
        margin: '0 auto',
        width: '100%',
      },
      '&.hasPadding': {
        maxWidth: '1432px',
        overflow: 'hidden'
      }
    },
    item: {
      // paddingLeft: 16,
      // paddingRight: 16,
      // paddingTop: '0 !important',
      // paddingBottom: '0 !important',
    }
  }
};

const DefaultTheme = props => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles theme={theme}/>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </StylesProvider>
  );
};

export {theme, DefaultTheme as default}