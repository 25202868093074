
const lists = (theme) => {
  return `
    ul:not([class]),
    ol:not([class]) {
      font-family: ${theme.fontFamily};
      line-height: 22px;
      margin: 0;
      padding-left: 20px;
      list-style: none;

      li {
        margin-bottom: 10px;
        position: relative;
      }
    }

    ul:not([class]) {
      li {
        &:not(.no-dot) {
          &:before {
            content: "\\2022";
            color: ${theme.palette.vividBlue};
            line-height: 0;
            position: absolute;
            left: -20px;
            top: 11px;
          }
        }

        ul:not([class]) {
          li {
            &:not(.no-dot) {
              &:before {
                content: "\u25E6";
              }
            }
          }
        }
      }
    }

    ol:not([class]) {
      counter-reset: ol-counter;
      padding-left: 20px;

      li {
        counter-increment: ol-counter;

        &:before {
          content: counter(ol-counter)'.';
          color: ${theme.palette.vividBlue};
          line-height: 0;
          position: absolute;
          left: -20px;
          top: 11px;
        }
      }
    }
  `
}

export default lists