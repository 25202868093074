
export default (theme) => ({
  MuiInputBase: {
    input: {
      fontSize: '14px',
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
      },
      '&::placeholder': {
        lineHeight: 'normal',
        color: theme.palette.darkGrey,
        opacity: 1
      }
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: '22px',
      borderColor: theme.palette.middleGrey,
      backgroundColor: 'white',
      '&:hover, &$focused': {
        backgroundColor: theme.palette.lightGrey,
      },
      "& $notchedOutline": {
        borderColor: theme.palette.middleGrey,
      },
      "&:hover $notchedOutline": {
        borderColor: theme.palette.middleGrey,
      },
      "&$focused $notchedOutline": {
        borderColor: theme.palette.middleGrey,
        borderWidth: 1,
      },
      "&$error $notchedOutline, & input:invalid + fieldset": {
        borderColor: theme.palette.error.form,
      },
      '& input:not(:placeholder-shown) + fieldset': {
        borderColor: theme.palette.skyBlue,
      },
      '& textarea:not(:placeholder-shown) + fieldset': {
        borderColor: theme.palette.skyBlue,
      },
    },
    input: {
      padding: '9px 19px',
      color: theme.palette.darkGrey,
      minHeight: 20,

      [theme.breakpoints.up('md')]: {
        padding: '12px 19px',
      }
    }
  },
  MuiFormControlLabel: {
    label: {
      fontSize: '14px',
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
      }
    }
  }
})